<template>
  <v-card>
    <v-card-title class="px-2">
      <Comeback /> {{ $t('DrugToBlame567') }} <span class="error--text ml-2">({{ $t('narcotics_of_drugs_category_type_5') }})</span> <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :loading="exportLoading"
        class="d-none d-md-block"
        :disabled="exportLoading"
        @click="exportExcel"
      >
        {{ $t('excel') }}
      </v-btn>
      <v-btn
        color="primary"
        class="d-block d-md-none"
        :loading="exportLoading"
        :disabled="exportLoading"
        icon
        fab
        outlined
        @click="exportExcel"
      >
        <v-icon>{{ mdiFileExcelOutline }}</v-icon>
      </v-btn>
    </v-card-title>
    <PsychotropicSearch
      @onSendDate="serachReport"
    />

    <!-- Day -->
    <v-data-table
      v-if="date_type_id == 1"
      :headers="columns"
      :items="dataTableList"
      :options.sync="options"
      :loading="loading"
      disable-sort
      hide-default-header
      hide-default-footer
      :loading-text="$t('data_loading')"
      :no-data-text="$t('no_information')"
    >
      <template v-slot:header>
        <tr height="43px">
          <th
            rowspan="2"
            class="text-left pl-4"
            style="min-width: 150px"
          >
            {{ $t('day') }} {{ $t('month') }} {{ $t('year') }}
          </th>
          <th
            rowspan="2"
            class="text-left pl-4"
            style="min-width: 350px"
          >
            {{ $t('name_and_potency_2') }}
          </th>
          <th
            rowspan="2"
            class="text-left pl-4"
            style="min-width: 200px"
          >
            {{ $t('no_model_production') }}
          </th>
          <th
            rowspan="2"
            class="text-left pl-4"
            style="min-width: 150px"
          >
            {{ $t('derived_from') }}
          </th>
          <th colspan="6">
            {{ $t('dispense') }}
          </th>
          <th colspan="4">
            {{ $t('amount_quantity5') }}
          </th>
          <th
            rowspan="2"
            class="text-center pl-4"
            style="min-width: 200px"
          >
            {{ $t('unit') }}
          </th>
        </tr>
        <tr>
          <th
            class="text-left pl-4"
            style="min-width: 120px"
          >
            {{ $t('prefix') }}
          </th>
          <th
            class="text-left pl-4"
            style="min-width: 150px"
          >
            {{ $t('firstname') }}
          </th>
          <th
            class="text-left pl-4"
            style="min-width: 150px"
          >
            {{ $t('lastname') }}
          </th>
          <th
            class="text-center pl-4"
            style="min-width: 300px"
          >
            {{ $t('idcard') }}/{{ $t('passport') }}/{{ $t('other_government') }}
          </th>
          <th
            class="text-left pl-4"
            style="min-width: 200px"
          >
            {{ $t('address') }}
          </th>
          <th
            class="text-left pl-4"
            style="min-width: 150px"
          >
            {{ $t('age') }}
          </th>
          <th
            class="text-right pr-4"
            style="min-width: 200px"
          >
            {{ $t('peak') }}
          </th>
          <th
            class="text-right pr-4"
            style="min-width: 200px"
          >
            {{ $t('pick_up_bring') }}
          </th>
          <th
            class="text-right pr-4"
            style="min-width: 200px"
          >
            {{ $t('to_pay') }}
          </th>
          <th
            class="text-right pr-4"
            style="min-width: 200px"
          >
            {{ $t('balance') }}
          </th>
        </tr>
      </template>
    </v-data-table>

    <!-- Monthly -->
    <v-data-table
      v-if="date_type_id ==2"
      :headers="columns2"
      :items="dataTableList"
      :options.sync="options"
      :loading="loading"
      disable-sort
      hide-default-header
      hide-default-footer
      :loading-text="$t('data_loading')"
      :no-data-text="$t('no_information')"
    >
      <template v-slot:header>
        <tr height="43px">
          <th
            rowspan="2"
            class="text-left pl-4"
            style="min-width: 150px"
          >
            {{ $t('day') }} {{ $t('month') }} {{ $t('year') }}
          </th>
          <th
            rowspan="2"
            class="text-left pl-4"
            style="min-width: 350px"
          >
            {{ $t('name_and_potency_2') }}
          </th>
          <th
            rowspan="2"
            class="text-left pl-4"
            style="min-width: 200px"
          >
            {{ $t('no_model_production') }}
          </th>
          <th
            rowspan="2"
            class="text-left pl-4"
            style="min-width: 150px"
          >
            {{ $t('derived_from') }}
          </th>
          <th colspan="4">
            {{ $t('amount_quantity5') }}
          </th>
          <th
            rowspan="2"
            class="text-center pl-4"
            style="min-width: 200px"
          >
            {{ $t('unit') }}
          </th>
          <th
            rowspan="2"
            class="text-center pl-4"
            style="min-width: 200px"
          >
            {{ $t('note') }}
          </th>
        </tr>
        <tr height="43px">
          <th
            class="text-right pr-4"
            style="min-width: 200px"
          >
            {{ $t('peak') }}
          </th>
          <th
            class="text-right pr-4"
            style="min-width: 200px"
          >
            {{ $t('pick_up_bring') }}
          </th>
          <th
            class="text-right pr-4"
            style="min-width: 200px"
          >
            {{ $t('to_pay') }}
          </th>
          <th
            class="text-right pr-4"
            style="min-width: 200px"
          >
            {{ $t('balance') }}
          </th>
        </tr>
      </template>
    </v-data-table>

    <!-- Yearly -->
    <v-data-table
      v-if="date_type_id ==3"
      :headers="columns3"
      :items="dataTableList"
      :options.sync="options"
      :loading="loading"
      disable-sort
      hide-default-header
      hide-default-footer
      :loading-text="$t('data_loading')"
      :no-data-text="$t('no_information')"
    >
      <template v-slot:header>
        <tr height="43px">
          <th
            rowspan="2"
            class="text-left pl-4"
            style="min-width: 150px"
          >
            {{ $t('day') }} {{ $t('month') }} {{ $t('year') }}
          </th>
          <th
            rowspan="2"
            class="text-left pl-4"
            style="min-width: 350px"
          >
            {{ $t('name_and_potency_2') }}
          </th>
          <th colspan="5">
            {{ $t('amount_quantity5') }}
          </th>
        </tr>
        <tr height="43px">
          <th
            class="text-right pr-4"
            style="min-width: 200px"
          >
            {{ $t('peak') }}
          </th>
          <th
            class="text-right pr-4"
            style="min-width: 200px"
          >
            {{ $t('pick_up_bring') }}
          </th>
          <th
            class="text-right pr-4"
            style="min-width: 200px"
          >
            {{ $t('to_pay') }}
          </th>
          <th
            class="text-right pr-4"
            style="min-width: 200px"
          >
            {{ $t('balance') }}
          </th>
          <th
            class="text-left pl-4"
            style="min-width: 200px"
          >
            {{ $t('note') }}
          </th>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import PsychotropicSearch from './PsychotropicSearch.vue'
import Comeback from '../Comeback.vue'
import { reportPsychotropic5, htmlToExcel } from '../useExcel'
import { i18n } from '@/plugins/i18n'
import { sumdate } from '@/plugins/filters'

export default {

  components: {
    PsychotropicSearch,
    Comeback,
  },
  setup() {
    const moment = require('moment')
    const payloadData = ref({})
    const loading = ref(false)
    const exportLoading = ref(false)
    const options = ref({})
    const dataTableList = ref([])
    const columns = ref([
      { text: `${i18n.t('day')} ${i18n.t('month')} ${i18n.t('year')}`, value: 'date' },
      { text: i18n.t('name_and_potency_2'), value: 'drug_name' },
      { text: i18n.t('no_model_production'), value: 'drugorder_id' },
      { text: i18n.t('derived_from'), value: 'drugfrom_name' },
      { text: i18n.t('prefix'), value: 'customer_prefix' },
      { text: i18n.t('firstname'), value: 'customer_fname' },
      { text: i18n.t('lastname'), value: 'customer_lname' },
      { text: `${i18n.t('idcard')}/${i18n.t('passport')}/${i18n.t('other_government')}`, value: 'customer_idcard' },
      { text: i18n.t('address'), value: 'customer_address' },
      { text: i18n.t('age'), value: 'customer_age' },
      { text: i18n.t('peak'), value: 'drug_top', align: 'end' },
      { text: i18n.t('pick_up_bring'), value: 'drug_in', align: 'end' },
      { text: i18n.t('to_pay'), value: 'drug_out', align: 'end' },
      { text: i18n.t('balance'), value: 'drug_balance', align: 'end' },
      { text: i18n.t('unit'), value: 'drug_unit', align: 'center' },
    ])
    const columns2 = ref([
      { text: `${i18n.t('day')} ${i18n.t('month')} ${i18n.t('year')}`, value: 'date' },
      { text: i18n.t('name_and_potency_2'), value: 'drug_name' },
      { text: i18n.t('no_model_production'), value: 'drugorder_id' },
      { text: i18n.t('derived_from'), value: 'drugfrom_name' },
      { text: i18n.t('peak'), value: 'drug_top', align: 'end' },
      { text: i18n.t('pick_up_bring'), value: 'drug_in', align: 'end' },
      { text: i18n.t('to_pay'), value: 'drug_out', align: 'end' },
      { text: i18n.t('balance'), value: 'drug_balance', align: 'end' },
      { text: i18n.t('unit'), value: 'drug_unit', align: 'center' },
      { text: i18n.t('note'), value: 'drug_detail', align: 'center' },
    ])
    const columns3 = ref([
      { text: `${i18n.t('day')} ${i18n.t('month')} ${i18n.t('year')}`, value: 'date' },
      { text: i18n.t('name_and_potency_2'), value: 'drug_name' },
      { text: i18n.t('peak'), value: 'drug_top', align: 'end' },
      { text: i18n.t('pick_up_bring'), value: 'drug_in', align: 'end' },
      { text: i18n.t('to_pay'), value: 'drug_out', align: 'end' },
      { text: i18n.t('balance'), value: 'drug_balance', align: 'end' },
      { text: i18n.t('note'), value: 'drug_detail' },
    ])
    const date_type_id = ref(null)
    const dataHeader = ref([])
    const mySubTitle = ref([])
    const mergeRow = ref([])
    const myTitle = ref([])
    const new_title = ref('')
    const new_doctor = ref('')
    const new_doctor_license = ref('')
    const new_shop_name = ref('')
    const new_shop_address = ref('')
    const new_shop_district = ref('')
    const new_shop_tel = ref('')
    const new_shop_amphoe = ref('')
    const new_shop_province = ref('')
    const new_shop_fax = ref('')

    const serachReport = async payload => {
      payloadData.value = payload
      loading.value = true
      payload.date_type_id = payload.date_type_id == 4 ? 1 : payload.date_type_id
      date_type_id.value = payload.date_type_id
      const {
        datayear,
        datamonth,
        dataday,
        title,
        doctor,
        doctor_license,
        shop_name,
        shop_address,
        shop_district,
        shop_amphoe,
        shop_province,
        shop_tel,
        shop_fax,
      } = await reportPsychotropic5(payload)

      if (payload.date_type_id == '1') {
        dataTableList.value = dataday && dataday.length > 0 ? fixedData(dataday) : []
        dataHeader.value = [
          'date',
          'drug_name',
          'drugorder_id',
          'drugfrom_name',
          'customer_prefix',
          'customer_fname',
          'customer_lname',
          'customer_idcard',
          'customer_address',
          'customer_age',
          'drug_top',
          'drug_in',
          'drug_out',
          'drug_balance',
          'drug_unit',
          'drug_detail',
        ]
        mySubTitle.value = [
          '',
          '',
          '',
          '',
          `${i18n.t('dispense')}`,
          '',
          '',
          '',
          '',
          '',
          `${i18n.t('amount_quantity5')}`,
          '',
          '',
          '',
        ]
        mergeRow.value = [
          { s: { r: 6, c: 4 }, e: { r: 6, c: 9 } },
          { s: { r: 6, c: 10 }, e: { r: 6, c: 13 } },
        ]

        myTitle.value = [
          `${i18n.t('day')} ${i18n.t('month')} ${i18n.t(
            'year',
          )} `,
          `${i18n.t('name_and_potency_2')}`,
          i18n.t('no_model_production'),
          i18n.t('derived_from'),

          /* O */
          i18n.t('prefix'),
          i18n.t('firstname'),
          i18n.t('lastname'),
          `${i18n.t('idcard')
          }/${
            i18n.t('passport')
          }/${
            i18n.t('other_government')}`,
          i18n.t('address'),
          i18n.t('age'),

          /* O */
          i18n.t('peak'),
          i18n.t('pick_up_bring'),
          i18n.t('to_pay'),
          i18n.t('balance'),
          i18n.t('unit'),
          i18n.t('note'),
        ]
      } else if (payload.date_type_id == '2') {
        dataTableList.value = datamonth && datamonth.length > 0 ? fixedData(datamonth) : []
        dataHeader.value = [
          'date',
          'drug_name',
          'drugorder_id',
          'drugfrom_producer',
          'drugfrom_name',
          'customer_amount',
          'drug_top',
          'drug_in',
          'drug_out',
          'drug_balance',
          'drug_unit',
          'drug_detail',
        ]
        mySubTitle.value = [
          '',
          '',
          '',
          '',
          '',
          '',
          `${i18n.t('amount_quantity5')}`,
          '',
          '',
        ]
        mergeRow.value = [{ s: { r: 6, c: 6 }, e: { r: 6, c: 9 } }]

        myTitle.value = [
          `${i18n.t('day')} ${i18n.t('month')} ${i18n.t(
            'year',
          )} `,
          `${i18n.t('name_and_potency_2')}`,
          i18n.t('no_model_production'),
          i18n.t('manufacturer_name'),
          i18n.t('derived_from'),
          i18n.t('dispense'),
          i18n.t('peak'),
          i18n.t('pick_up_bring'),
          i18n.t('to_pay'),
          i18n.t('balance'),
          i18n.t('unit'),
          i18n.t('note'),
        ]
      } else if (payload.date_type_id == '3') {
        const dataYearMap = []
        for (const item of datayear) {
          if (item.month_detail) {
            let c = 0
            for (const sub_item of item.month_detail) {
              dataYearMap.push({
                date: c == 0 ? item.month : '',
                drug_name: sub_item.drug_name,
                drug_top: sub_item.drug_top,
                drug_out: sub_item.drug_out,
                drug_in: sub_item.drug_in,
                drug_balance: sub_item.drug_balance,
                drug_detail: sub_item.drug_detail ? sub_item.drug_detail : '',
              })
              c++
            }
          } else {
            dataYearMap.push({ date: item.month })
          }
        }
        dataTableList.value = dataYearMap
        dataHeader.value = [
          'date',
          'drug_name',
          'drug_top',
          'drug_in',
          'drug_out',
          'drug_balance',
          'drug_detail',
        ]
        myTitle.value = [
          `${i18n.t('day')} ${i18n.t('month')} ${i18n.t(
            'year',
          )} `,
          `${i18n.t('name_and_potency_2')}`,
          i18n.t('peak'),
          i18n.t('pick_up_bring'),
          i18n.t('to_pay'),
          i18n.t('balance'),
          i18n.t('note'),
        ]
        mySubTitle.value = [
          '',
          '',
          `${i18n.t('amount_quantity5')}`,
          'a',
          '',
          '',
        ]
        mergeRow.value = [{ s: { r: 6, c: 2 }, e: { r: 6, c: 5 } }]
      }
      new_title.value = title
      new_doctor.value = doctor
      new_doctor_license.value = doctor_license
      new_shop_name.value = shop_name
      new_shop_address.value = shop_address
      new_shop_district.value = shop_district
      new_shop_tel.value = shop_tel
      new_shop_amphoe.value = shop_amphoe
      new_shop_province.value = shop_province
      new_shop_fax.value = shop_fax
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const fixedData = data => {
      data = data.map(item => {
        const date = moment(item.date).isValid()
          ? sumdate(item.date, i18n.locale)
          : item.date

        return { ...item, date }
      })

      return data
    }

    const exportExcel = async () => {
      exportLoading.value = true

      const { start, end } = payloadData.value
      let colsPan = 0
      const fileName = `${i18n.t('DrugToBlame567')} ${i18n.t(
        'since',
      )} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)}.xlsx`

      let html = ''
      if (date_type_id.value == 1) {
        colsPan = 16
        html = `
            <table cellpadding="1" cellspacing="1" >
              <tbody>
                    <tr>
                        <td style="text-align:left "   colspan="16" > <h4> <strong>${i18n.t('DrugToBlame567')} / ${date_type_id.value == 1 ? i18n.t('day') : date_type_id.value == 2 ? i18n.t('month') : i18n.t('year')} </strong></h4></td>
                    </tr>
                    <tr>
                        <td style="text-align:left " colspan="16">${i18n.t('reportpsychotropic2_name_1')}</td>
                    </tr>
                    <tr>
                        <td style="text-align:left " colspan="16">${`${i18n.t('reportpsychotropic2_name_2_2')} ${new_title.value}`}</td>
                    </tr>
                    <tr>
                        <td style="text-align:left " colspan="16">${`${i18n.t('reportpsychotropic2_name_3')} ${new_doctor.value} ${i18n.t('reportpsychotropic2_name_4')} ${new_doctor_license.value}  ${i18n.t('reportpsychotropic2_name_5')} ${new_shop_name.value}`}</td>
                    </tr>
                    <tr>
                        <td style="text-align:left " colspan="16">${
  i18n.t('reportpsychotropic2_name_6')} ${
  new_shop_address.value
} ${i18n.t('reportpsychotropic2_name_7')} ${
  new_shop_district.value
}  ${i18n.t('reportpsychotropic2_name_8')} ${
  new_shop_amphoe.value
} ${i18n.t('reportpsychotropic2_name_9')} ${
  new_shop_province.value
} ${i18n.t('reportpsychotropic3_name_10')} ${
  new_shop_tel.value
} ${i18n.t('reportpsychotropic3_name_11')} ${new_shop_fax.value}
                    </td>
                    </tr>
                    <tr> 
                        <td style="text-align:left " colspan="16" >${i18n.t('reportpsychotropic2_name_12')}</td>
                    </tr>
                </tbody>
                <tbody  >
                
                    <tr  >
                        <td colspan="1" rowspan="2" style="text-align:center; width:86px; vertical-align: minden;border: 1px solid black; ">
                            <strong>${i18n.t('day')} ${i18n.t('month')} ${i18n.t('year')}</strong></td>
                        <td colspan="1" rowspan="2" style="text-align:center; width:300px;border: 1px solid black;">
                            <strong>${i18n.t('name_and_potency_2')}</strong></td>
                        <td colspan="1" rowspan="2" style="text-align:center; width:131px;border: 1px solid black;">
                            <strong>${i18n.t('no_model_production')}</strong></td>
                        <td colspan="1" rowspan="2" style="text-align:center; width:95px;border: 1px solid black;"><strong>${i18n.t('derived_from')}</strong></td>
                        <td colspan="6" rowspan="1" style="text-align:center; width:500px;border: 1px solid black; "><strong>${i18n.t('dispense')}</strong></td>
                        <td colspan="6" rowspan="1" style="text-align:center; width:550px;border: 1px solid black; ">
                            <strong>${i18n.t('amount_quantity5')}</strong></td>
                    </tr>
                    <tr>
                        <td style="text-align:center; width:50px;border: 1px solid black;"><strong>${i18n.t('prefix')}</strong></td>
                        <td style="text-align:center; width:120px;border: 1px solid black;"><strong>${i18n.t('name')}ชื่อ</strong></td>
                        <td style="text-align:center; width:120px;border: 1px solid black;"><strong>${i18n.t('lastname')} นามสกุล</strong></td>
                        <td style="text-align:center; width:400px;border: 1px solid black;">
                            <strong>${i18n.t('idcard')}/${i18n.t('passport')}/${i18n.t('other_government')}</strong>
                        </td>
                        <td style="text-align:center; width:150px;border: 1px solid black;"><strong>${i18n.t('address')}</strong></td>
                        <td style="text-align:center; width:50px;border: 1px solid black;"><strong>${i18n.t('age')}</strong></td>
                        <td style="text-align:center; width:50px;border: 1px solid black;"><strong>${i18n.t('peak')}</strong></td>
                        <td style="text-align:center; width:50px;border: 1px solid black;"><strong>${i18n.t('pick_up_bring')}</strong></td>
                        <td style="text-align:center; width:50px;border: 1px solid black;"><strong>${i18n.t('to_pay')}</strong></td>
                        <td style="text-align:center; width:50px;border: 1px solid black;"><strong>${i18n.t('balance')}</strong></td>
                        <td style="text-align:center; width:60px;border: 1px solid black;"><strong>${i18n.t('unit')}</strong></td>
                        <td style="text-align:center; width:60px;border: 1px solid black;"><strong>${i18n.t('note')}</strong></td>
                    </tr>
                     $rowData
      `
        let rows = ''
        for (const item of dataTableList.value) {
          rows += `
                  <tr>
                    <td style="text-align:center;border: 1px solid black;">${item.date ? item.date : ''}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drug_name ? item.drug_name : ''}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drugorder_id ? item.drugorder_id : ''}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drugfrom_name ? item.drugfrom_name : ''}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.customer_prefix ? item.customer_prefix : ''}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.customer_fname ? item.customer_fname : ''}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.customer_lname ? item.customer_lname : ''}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.customer_idcard ? item.customer_idcard : ''}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.customer_address ? item.customer_address : ''}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.customer_age ? item.customer_age : ''}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drug_top ? item.drug_top : 0}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drug_in ? item.drug_in : 0}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drug_out ? item.drug_out : 0}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drug_balance ? item.drug_balance : 0} </td>
                    <td style="text-align:center;border: 1px solid black;">${item.drug_unit ? item.drug_unit : ''} </td>
                    <td style="text-align:center;border: 1px solid black;">${item.drug_detail ? item.drug_detail : ''} </td>
                  </tr>
          `
        }
        html = html.replace('$rowData', rows)
      } else if (date_type_id.value == 2) {
        colsPan = 10
        html = `
            <table cellpadding="1" cellspacing="1" >
              <tbody>
                    <tr>
                        <td style="text-align:center "   colspan="10" ><strong> ${
  i18n.t('DrugToBlame567')} / ${date_type_id.value == 1 ? i18n.t('day') : date_type_id.value == 2 ? i18n.t('month') : i18n.t('year')
} </strong></td>
                    </tr>
                    <tr>
                        <td style="text-align:center " colspan="10">${i18n.t('reportpsychotropic2_name_1')}</td>
                    </tr>
                    <tr>
                        <td style="text-align:center " colspan="10">${`${i18n.t('reportpsychotropic2_name_2_2')} ${new_title}`}</td>
                    </tr>
                    <tr>
                        <td style="text-align:center " colspan="10">${`${
    i18n.t('reportpsychotropic2_name_3')} ${new_doctor} ${i18n.t('reportpsychotropic2_name_4')} ${new_doctor_license}  ${i18n.t('reportpsychotropic2_name_5')} ${new_shop_name}`}</td>
                    </tr>
                    <tr>
                        <td style="text-align:center " colspan="10">${
  i18n.t('reportpsychotropic2_name_6')} ${
  new_shop_address.value
} ${i18n.t('reportpsychotropic2_name_7')} ${
  new_shop_district.value
}  ${i18n.t('reportpsychotropic2_name_8')} ${
  new_shop_amphoe.value
} ${i18n.t('reportpsychotropic2_name_9')} ${
  new_shop_province.value
} ${i18n.t('reportpsychotropic3_name_10')} ${
  new_shop_tel.value
} ${i18n.t('reportpsychotropic3_name_11')} ${new_shop_fax.value}
                    </td>
                    </tr>
                    <tr> 
                        <td style="text-align:center " colspan="10" >${i18n.t('reportpsychotropic2_name_12')}</td>
                    </tr>
                </tbody>
                <tbody  >
                
                    <tr  >
                        <td colspan="1" rowspan="2" style="text-align:center; width:86px; vertical-align: minden;border: 1px solid black; ">
                            <strong>${i18n.t('day')} ${i18n.t('month')} ${i18n.t('year')}</strong></td>
                        <td colspan="1" rowspan="2" style="text-align:center; width:300px;border: 1px solid black;">
                            <strong>${i18n.t('name_and_potency_2')}</strong></td>
                        <td colspan="1" rowspan="2" style="text-align:center; width:131px;border: 1px solid black;">
                            <strong>${i18n.t('no_model_production')}</strong></td>
                        <td colspan="1" rowspan="2" style="text-align:center; width:95px;border: 1px solid black;"><strong>${i18n.t('derived_from')}</strong></td>
                        <td colspan="6" rowspan="1" style="text-align:center; width:550px;border: 1px solid black; ">
                            <strong>${i18n.t('amount_quantity5')}</strong></td>
                    </tr>
                    <tr>
  
                        <td style="text-align:center; width:50px;border: 1px solid black;"><strong>${i18n.t('peak')}</strong></td>
                        <td style="text-align:center; width:50px;border: 1px solid black;"><strong>${i18n.t('pick_up_bring')}</strong></td>
                        <td style="text-align:center; width:50px;border: 1px solid black;"><strong>${i18n.t('to_pay')}</strong></td>
                        <td style="text-align:center; width:50px;border: 1px solid black;"><strong>${i18n.t('balance')}</strong></td>
                        <td style="text-align:center; width:60px;border: 1px solid black;"><strong>${i18n.t('unit')}</strong></td>
                        <td style="text-align:center; width:60px;border: 1px solid black;"><strong>${i18n.t('note')}</strong></td>
                    </tr>
                    $rowData
      `

        let rows = ''
        for (const item of dataTableList.value) {
          rows += `
                  <tr>
                    <td style="text-align:center;border: 1px solid black;">${item.date ? item.date : ''}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drug_name ? item.drug_name : ''}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drugorder_id ? item.drugorder_id : ''}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drugfrom_producer ? item.drugfrom_producer : ''}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drug_top ? item.drug_top : 0}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drug_in ? item.drug_in : 0}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drug_out ? item.drug_out : 0}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drug_balance ? item.drug_balance : 0}&nbsp;</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drug_unit ? item.drug_unit : ''}&nbsp;</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drug_detail ? item.drug_detail : ''}&nbsp;</td>
                  </tr>
          `
        }
        html = html.replace('$rowData', rows)
      } else if (date_type_id.value == 3) {
        colsPan = 7
        html = `
            <table cellpadding="1" cellspacing="1" >
              <tbody>
                    <tr>
                        <td style="text-align:center "   colspan="7" ><strong> ${
  i18n.t('DrugToBlame567')} / ${date_type_id.value == 1 ? i18n.t('day') : date_type_id.value == 2 ? i18n.t('month') : i18n.t('year')
} </strong></td>
                    </tr>
                    <tr>
                        <td style="text-align:center " colspan="7">${i18n.t('reportpsychotropic2_name_1')}</td>
                    </tr>
                    <tr>
                        <td style="text-align:center " colspan="7">${`${i18n.t('reportpsychotropic2_name_2_2')} ${new_title.value}`}</td>
                    </tr>
                    <tr>
                        <td style="text-align:center " colspan="7">${`${
    i18n.t('reportpsychotropic2_name_3')} ${new_doctor.value} ${i18n.t('reportpsychotropic2_name_4')} ${new_doctor_license.value}  ${i18n.t('reportpsychotropic2_name_5')} ${new_shop_name.value}`}</td>
                    </tr>
                    <tr>
                        <td style="text-align:center " colspan="7">${
  i18n.t('reportpsychotropic2_name_6')} ${
  new_shop_address.value
} ${i18n.t('reportpsychotropic2_name_7')} ${
  new_shop_district.value
}  ${i18n.t('reportpsychotropic2_name_8')} ${
  new_shop_amphoe.value
} ${i18n.t('reportpsychotropic2_name_9')} ${
  new_shop_province.value
} ${i18n.t('reportpsychotropic3_name_10')} ${
  new_shop_tel.value
} ${i18n.t('reportpsychotropic3_name_11')} ${new_shop_fax.value}
                    </td>
                    </tr>
                    <tr> 
                        <td style="text-align:center " colspan="7" >${i18n.t('reportpsychotropic2_name_12')}</td>
                    </tr>
                </tbody>
                <tbody  >
                    <tr  >
                        <td colspan="1" rowspan="2" style="text-align:center; width:86px; vertical-align: minden;border: 1px solid black; ">
                            <strong>${i18n.t('day')} ${i18n.t('month')} ${i18n.t('year')}</strong></td>
                        <td colspan="1" rowspan="2" style="text-align:center; width:300px;border: 1px solid black;">
                            <strong>${i18n.t('name_and_potency_2')}</strong></td>
    
                        <td colspan="5" rowspan="1" style="text-align:center; width:550px;border: 1px solid black; ">
                            <strong>${i18n.t('amount_quantity5')}</strong></td>
                    </tr>
                    <tr>
                        <td style="text-align:center; width:50px;border: 1px solid black;"><strong>${i18n.t('peak')}</strong></td>
                        <td style="text-align:center; width:50px;border: 1px solid black;"><strong>${i18n.t('pick_up_bring')}</strong></td>
                        <td style="text-align:center; width:50px;border: 1px solid black;"><strong>${i18n.t('to_pay')}</strong></td>
                        <td style="text-align:center; width:50px;border: 1px solid black;"><strong>${i18n.t('balance')}</strong></td> 
                        <td style="text-align:center; width:60px;border: 1px solid black;"><strong>${i18n.t('note')}</strong></td>
                    </tr>
                        $rowData
              
      `
        let rows = ''
        for (const item of dataTableList.value) {
          rows += `
                  <tr>
                    <td style="text-align:center;border: 1px solid black;">${item.date}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drug_name ? item.drug_name : ''}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drug_top ? item.drug_top : 0}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drug_in ? item.drug_in : 0}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drug_out ? item.drug_out : 0}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drug_balance ? item.drug_balance : 0}</td>
                    <td style="text-align:center;border: 1px solid black;">${item.drug_detail ? item.drug_detail : ''}&nbsp;</td>
                  </tr>
          `
        }
        html = html.replace('$rowData', rows)
      }
      html += `
            <tr></tr>
            <tr>
                <td colspan="${colsPan - 5}"></td>
                <td colspan="4"   style="text-align:center;">${i18n.t('reportpsychotropic2_name_13')}...........................................${i18n.t('reportpsychotropic2_name_14')} </td>
                <td></td>
            </tr>
            <tr>
                <td colspan="${colsPan - 5}"></td>
                <td colspan="4" style="text-align:center;">(${new_doctor.value})</td>
                <td></td>
            </tr>
            <tr>
                <td colspan="${colsPan}"  style="text-align:left;">${i18n.t('reportpsychotropic2_name_15')}${i18n.t('reportpsychotropic2_name_16')}</td>
            </tr>
            <tr>
                <td colspan="${colsPan}"  style="text-align:left;">${i18n.t('reportpsychotropic2_name_17')}${i18n.t('reportpsychotropic2_name_18')}</td>
            </tr>
            <tr>
                <td colspan="${colsPan}"  style="text-align:left;">${i18n.t('reportpsychotropic2_name_19')}</td>
            </tr>
            </tbody>
          </table>
        `
      htmlToExcel({ html, filename: fileName })
      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      serachReport,
      dataTableList,
      options,
      columns,
      dataHeader,
      myTitle,
      mySubTitle,
      mergeRow,
      loading,
      columns2,
      columns3,
      date_type_id,
      exportLoading,
      exportExcel,
      mdiFileExcelOutline,
    }
  },
}
</script>
